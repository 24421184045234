import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../components/container"

const Hero = ({ type, children }) => {
  const style =
    type === "primary"
      ? "bg-gradient-to-b from-gradationNavy-from to-gradationNavy-to text-white"
      : "bg-blue-50 text-blue-950"

  return (
    <div className={`${style} pt-20 relative overflow-hidden`}>
      <div className="">
        <div className="w-full absolute z-0 top-0 lg:-mt-11">
          <StaticImage
            layout="fullWidth"
            alt=""
            src={"../images/bg-element.png"}
            loading="eager"
            placeholder="none"
          />
        </div>

        <div className="relative z-10">
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  )
}

export default Hero
